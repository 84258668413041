const groups = {
  ADMIN: 'ADMIN',
  USER: 'USER',

  CAISSE_LIST: 'CAISSE_LIST',
  CAISSE_EDIT: 'CAISSE_EDIT',
  CAISSE_ADD: 'CAISSE_ADD',
  CAISSE_DELETE: 'CAISSE_DELETE',
  CAISSE_PRINT: 'CAISSE_PRINT',
  CAISSE_SUPERVISER: 'CAISSE_SUPERVISER',

  PRODUCTION_LIST: 'PRODUCTION_LIST',
  PRODUCTION_ADD: 'PRODUCTION_ADD',
  PRODUCTION_EDIT: 'PRODUCTION_EDIT',
  PRODUCTION_SUPERVISER: 'PRODUCTION_SUPERVISER',

  AVENANT_LIST: 'AVENANT_LIST',
  AVENANT_ADD: 'AVENANT_ADD',
  AVENANT_EDIT: 'AVENANT_EDIT',
  AVENANT_SUPERVISER: 'AVENANT_SUPERVISER',

  COMMISSION_LIST: 'COMMISSION_LIST',
  COMMISSION_PRINT: 'COMMISSION_PRINT',
  COMMISSION_SUPERVISER: 'COMMISSION_SUPERVISER',

  FICHE_LIST: 'FICHE_LIST',
  FICHE_ADD: 'FICHE_ADD',
  FICHE_EDIT: 'FICHE_EDIT',
  FICHE_DELETE: 'FICHE_DELETE',
  FICHE_SUPERVISER: 'FICHE_SUPERVISER',

  CHEQUE_LIST: 'CHEQUE_LIST',
  CHEQUE_EDIT: 'CHEQUE_EDIT',
  CHEQUE_ADD: 'CHEQUE_ADD',
  CHEQUE_DELETE: 'CHEQUE_DELETE',

  INSTANCE_COMMERCIALE_LIST: 'INSTANCE_COMMERCIALE_LIST',
  INSTANCE_COMMERCIALE_EDIT: 'INSTANCE_COMMERCIALE_EDIT',
  INSTANCE_COMMERCIALE_ADD: 'INSTANCE_COMMERCIALE_ADD',
  INSTANCE_COMMERCIALE_DELETE: 'INSTANCE_COMMERCIALE_DELETE',

  INSTANCE_GESTION_LIST: 'INSTANCE_GESTION_LIST',
  INSTANCE_GESTION_EDIT: 'INSTANCE_GESTION_EDIT',
  INSTANCE_GESTION_ADD: 'INSTANCE_GESTION_ADD',
  INSTANCE_GESTION_DELETE: 'INSTANCE_GESTION_DELETE',

  INSTANCE_DIRECTION_LIST: 'INSTANCE_DIRECTION_LIST',
  INSTANCE_DIRECTION_EDIT: 'INSTANCE_DIRECTION_EDIT',
  INSTANCE_DIRECTION_ADD: 'INSTANCE_DIRECTION_ADD',
  INSTANCE_DIRECTION_DELETE: 'INSTANCE_DIRECTION_DELETE',

  RECOMMANDATION_LIST: 'RECOMMANDATION_LIST',
  RECOMMANDATION_EDIT: 'RECOMMANDATION_EDIT',
  RECOMMANDATION_ADD: 'RECOMMANDATION_ADD',
  RECOMMANDATION_DELETE: 'RECOMMANDATION_DELETE',

  RESILIATION_LIST: 'RESILIATION_LIST',
  RESILIATION_EDIT: 'RESILIATION_EDIT',
  RESILIATION_ADD: 'RESILIATION_ADD',
  RESILIATION_DELETE: 'RESILIATION_DELETE',

  STATISTIQUES: 'STATISTIQUES',
  STATISTIQUES_RESILIATION: 'STATISTIQUES_RESILIATION',
  STATISTIQUES_COLLABORATEURS: 'STATISTIQUES_COLLABORATEURS',
  STATISTIQUES_COLLABORATEURS_SUPERVISER:
    'STATISTIQUES_COLLABORATEURS_SUPERVISER',
  STATISTIQUES_SUIVIHEBDO: 'STATISTIQUES_SUIVIHEBDO',
  STATISTIQUES_SUIVIHEBDO_SUPERVISER: 'STATISTIQUES_SUIVIHEBDO_SUPERVISER',
  STATISTIQUES_BIEN_ETRE: 'STATISTIQUES_BIEN_ETRE',
  STATISTIQUES_SATISFACTION: 'STATISTIQUES_SATISFACTION',
  STATISTIQUES_DIAGRAMMES: 'STATISTIQUES_DIAGRAMMES',
  STATISTIQUES_DIAGRAMMES_SUPERVISER: 'STATISTIQUES_DIAGRAMMES_SUPERVISER',

  CABINET_LIST: 'CABINET_LIST',
  CABINET_EDIT: 'CABINET_EDIT',
  CABINET_ADD: 'CABINET_ADD',
  CABINET_DELETE: 'CABINET_DELETE',
  CABINET_SUPERVISER: 'CABINET_SUPERVISER',

  OUTIL_MAXIREM: 'OUTIL_MAXIREM',
  OUTIL_DEMO_BIA: 'OUTIL_DEMO_BIA',
  OUTIL_INFOS_CCN: 'OUTIL_INFOS_CCN',
  OUTIL_BILAN_RETRAITE: 'OUTIL_BILAN_RETRAITE',
  OUTIL_DROIT_SUCCESSION: 'OUTIL_DROIT_SUCCESSION',
  OUTIL_IMPOT_REVENU: 'OUTIL_IMPOT_REVENU',
  OUTIL_DEMARCHE: 'OUTIL_DEMARCHE',
  OUTIL_SIMULATEUR_REVENUS: 'OUTIL_SIMULATEUR_REVENUS',

  TELETRANSMISSION_LIST: 'TELETRANSMISSION_LIST',
  TELETRANSMISSION_EDIT: 'TELETRANSMISSION_EDIT',

  DUEDSN_LIST: 'DUEDSN_LIST',
  DUEDSN_EDIT: 'DUEDSN_EDIT'
}

export default groups

export const roles = {
  COLLABORATEUR: 'ROLE_COLLABORATEUR',
  SUPERVISEUR_DEVIS: 'ROLE_SUPERVISEUR_DEVIS',
  SUPERVISEUR_INSTANCE: 'ROLE_SUPERVISEUR_INSTANCE'
}

export const GROUP_ADMIN = groups.ADMIN
export const GROUP_USER = groups.USER

export const groupsTree = [
  {
    label: 'Instance GESTION',
    view: groups.INSTANCE_GESTION_LIST,
    edit: groups.INSTANCE_GESTION_EDIT,
    add: groups.INSTANCE_GESTION_ADD,
    delete: groups.INSTANCE_GESTION_DELETE
  },
  {
    label: 'Instance DIRECTION',
    view: groups.INSTANCE_DIRECTION_LIST,
    edit: groups.INSTANCE_DIRECTION_EDIT,
    add: groups.INSTANCE_DIRECTION_ADD,
    delete: groups.INSTANCE_DIRECTION_DELETE
  },
  {
    label: 'Instance COMMERCIALE',
    view: groups.INSTANCE_COMMERCIALE_LIST,
    edit: groups.INSTANCE_COMMERCIALE_EDIT,
    add: groups.INSTANCE_COMMERCIALE_ADD,
    delete: groups.INSTANCE_COMMERCIALE_DELETE
  },

  {
    label: 'Fiche découverte',
    view: groups.FICHE_LIST,
    edit: groups.FICHE_EDIT,
    add: groups.FICHE_ADD,
    delete: groups.FICHE_DELETE,
    other: [
      {
        icon: 'supervisor_account',
        label: 'Superviser',
        group: groups.FICHE_SUPERVISER
      }
    ]
  },
  {
    label: 'Recommandation',
    view: groups.RECOMMANDATION_LIST,
    edit: groups.RECOMMANDATION_EDIT,
    add: groups.RECOMMANDATION_ADD,
    delete: groups.RECOMMANDATION_DELETE
  },

  {
    label: 'Production',
    view: groups.PRODUCTION_LIST,
    edit: groups.PRODUCTION_EDIT,
    add: groups.PRODUCTION_ADD,
    other: [
      {
        icon: 'supervisor_account',
        label: 'Superviser',
        group: groups.PRODUCTION_SUPERVISER
      }
    ]
  },

  {
    label: 'Avenants',
    view: groups.AVENANT_LIST,
    edit: groups.AVENANT_EDIT,
    add: groups.AVENANT_ADD,
    other: [
      {
        icon: 'supervisor_account',
        label: 'Superviser',
        group: groups.AVENANT_SUPERVISER
      }
    ]
  },

  {
    label: 'Télétransmissions',
    view: groups.TELETRANSMISSION_LIST,
    edit: groups.TELETRANSMISSION_EDIT
  },

  {
    label: 'DUE/DSN',
    view: groups.DUEDSN_LIST,
    edit: groups.DUEDSN_EDIT
  },

  {
    label: 'Commissions',
    view: groups.COMMISSION_LIST,
    other: [
      {
        icon: 'local_printshop',
        label: 'Imprimer',
        group: groups.COMMISSION_PRINT
      }
    ]
  },

  {
    label: 'Caisses',
    view: groups.CAISSE_LIST,
    edit: groups.CAISSE_EDIT,
    add: groups.CAISSE_ADD,
    delete: groups.CAISSE_DELETE,
    other: [
      {
        icon: 'local_printshop',
        label: 'Imprimer',
        group: groups.CAISSE_PRINT
      },
      {
        icon: 'supervisor_account',
        label: 'Superviser',
        group: groups.CAISSE_SUPERVISER
      }
    ]
  },
  {
    label: 'Chèques',
    view: groups.CHEQUE_LIST,
    edit: groups.CHEQUE_EDIT,
    add: groups.CHEQUE_ADD,
    delete: groups.CHEQUE_DELETE
  },

  {
    label: 'Cabinets comptables',
    view: groups.CABINET_LIST,
    edit: groups.CABINET_EDIT,
    add: groups.CABINET_ADD,
    delete: groups.CABINET_DELETE,
    other: [
      {
        icon: 'settings',
        label: 'Gestion',
        group: groups.CABINET_SUPERVISER
      }
    ]
  },

  {
    label: 'Résiliations',
    view: groups.RESILIATION_LIST,
    edit: groups.RESILIATION_EDIT,
    add: groups.RESILIATION_ADD,
    delete: groups.RESILIATION_DELETE
  },

  {
    label: 'Outils',
    other: [
      {
        label: 'Maxirem',
        group: groups.OUTIL_MAXIREM
      },
      {
        label: 'Démo portail BIA',
        group: groups.OUTIL_DEMO_BIA
      },
      {
        label: 'Infos CCN',
        group: groups.OUTIL_INFOS_CCN
      },
      {
        label: 'Bilan retraite',
        group: groups.OUTIL_BILAN_RETRAITE
      },
      {
        label: 'Droits de succession',
        group: groups.OUTIL_DROIT_SUCCESSION
      },
      {
        label: 'Impôts sur le revenu',
        group: groups.OUTIL_IMPOT_REVENU
      },
      {
        label: 'Démarche conseil / Connaissance client',
        group: groups.OUTIL_DEMARCHE
      },
      {
        label: 'Simulateur de revenus',
        group: groups.OUTIL_SIMULATEUR_REVENUS
      }
    ]
  },

  {
    label: 'Statistiques',
    other: [
      {
        label: 'Collaborateurs',
        group: groups.STATISTIQUES_COLLABORATEURS
      },
      {
        label: 'Collaborateurs - Superviser',
        icon: 'supervisor_account',
        group: groups.STATISTIQUES_COLLABORATEURS_SUPERVISER
      },
      {
        label: 'Suivi hebdomadaire',
        group: groups.STATISTIQUES_SUIVIHEBDO
      },
      {
        label: 'Suivi hebdomadaire - Superviser',
        icon: 'supervisor_account',
        group: groups.STATISTIQUES_SUIVIHEBDO_SUPERVISER
      },
      {
        label: 'Bien-être',
        group: groups.STATISTIQUES_BIEN_ETRE
      },
      {
        label: 'Satisfaction',
        group: groups.STATISTIQUES_SATISFACTION
      },
      {
        label: 'Résiliations',
        group: groups.STATISTIQUES_RESILIATION
      },
      {
        label: 'Diagrammes',
        group: groups.STATISTIQUES_DIAGRAMMES
      },
      {
        label: 'Diagrammes - Superviser',
        icon: 'supervisor_account',
        group: groups.STATISTIQUES_DIAGRAMMES_SUPERVISER
      }
    ]
  }
]
